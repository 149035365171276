@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');

html {
  font-family: 'Work Sans', sans-serif !important;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  letter-spacing: 0.25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Map */
.leaflet-container {
  height: 100%;
  background: transparent !important;
  position: relative;
}

.leaflet-control-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 72px;
}

/* Map zoom controls */
.leaflet-touch .leaflet-bar {
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0 !important;
  gap: 0.5rem;
}

.leaflet-left .leaflet-control {
  margin: 0 !important;
}

.leaflet-touch .leaflet-bar a {
  font-size: 1.125rem;
  color: #002a66;
  box-shadow: 0 0.0625rem 0.25rem rgba(37, 37, 37, 0.3);
}

.leaflet-bar a:not(.leaflet-disabled):hover {
  color: #002a66;
  background-color: #e5f0ff;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 0.25rem !important;
}

.leaflet-control-attribution {
  display: none;
}

fieldset {
  border: 0 !important;
}

.MuiAutocomplete-root {
  width: 100%;
}

/* Carousel Usecases */
.carousel-status {
  display: none;
}

.carousel.carousel-slider {
  height: 100%;
}

.carousel .control-dots {
  margin: 0 !important;
  padding: 0.75rem !important;
  background: #cce1ff;
}

.carousel .control-dots .dot.selected {
  background: #0050c0 !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: calc(100vh - 48px);
}

/* Media queries */
@media only screen and (max-width: 640px) {
  .carousel.carousel-slider {
    position: static !important;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: auto;
  }
}

/* Scrollbar */
.scrollable::-webkit-scrollbar,
.scrollableProjectFocusCountries::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollable::-webkit-scrollbar-track {
  background: #fdf2e7;
  margin: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #bf660d;
  border: 0.18rem solid #fdf2e7;
}

.scrollableUsecases::-webkit-scrollbar-track {
  background: #99c3ff;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.scrollableUsecases::-webkit-scrollbar-thumb {
  background-color: #0055cc;
  border: 0.18rem solid #99c3ff;
  border-radius: 0.5rem;
}

.scrollableProjectFocusCountries::-webkit-scrollbar {
  height: 0.5rem;
  margin-block: 5rem;
}

.scrollableUsecases::-webkit-scrollbar-track,
.scrollableProjectFocusCountries::-webkit-scrollbar-track {
  background: #99c3ff;
  margin: 0.5rem;
  border-radius: 0.5rem;
}

.scrollableUsecases::-webkit-scrollbar-thumb,
.scrollableProjectFocusCountries::-webkit-scrollbar-thumb {
  background-color: #0055cc;
  border: 0.18rem solid #99c3ff;
  border-radius: 0.5rem;
}

.MuiSlider-valueLabel {
  transform: translateY(-50%) scale(1) !important;
  background: transparent !important;
}

.MuiSlider-valueLabelLabel {
  font-weight: 600;
}

/* Popup */
.leaflet-popup-content-wrapper {
  background-color: #cce1ff !important;
}

.leaflet-popup-tip-container,
.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.leaflet-control-container {
  right: 4rem;
}

@media only screen and (max-width: 639px) {
  .leaflet-control-container {
    right: 1rem;
  }
}

.leaflet-popup-content {
  letter-spacing: 0.2rem;
  margin: 0.5rem !important;
  color: #002a66;
  text-align: center;
  font-size: 0.5rem !important;
}

/* Info icon */
#info:hover + div {
  display: block;
}

.multiIcon {
  width: 48px !important;
  padding-left: 52px !important;
  height: 52px !important;
  background-size: 100% 100% !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes changeYemenBg {
  0%,
  100% {
    background: url('./assets/images/WATERPEACE.svg');
  }
  50% {
    background: url('./assets/images/climateresilience.svg');
  }
}

@keyframes changePeruBg {
  0%,
  100% {
    background: url('./assets/images/protection.svg');
  }
  50% {
    background: url('./assets/images/wefe.svg');
  }
}

@keyframes changeNileBg {
  0%,
  100% {
    background: url('./assets/images/TWM.svg');
  }
  50% {
    background: url('./assets/images/WATERPEACE.svg');
  }
}

@keyframes changeZambiaBg {
  0%,
  100% {
    background: url('./assets/images/protection.svg');
  }
  50% {
    background: url('./assets/images/IWRM.svg');
  }
}

@keyframes changeTunisiaBg {
  0%,
  100% {
    background: url('./assets/images/IWRM.svg');
  }
  33% {
    background: url('./assets/images/climateresilience.svg');
  }
  66% {
    background: url('./assets/images/protection.svg');
  }
}

@keyframes changeAfricaBg {
  0%,
  100% {
    background: url('./assets/images/WATERPEACE.svg');
  }
  50% {
    background: url('./assets/images/TWM.svg');
  }
}

.yemenIcons {
  background: url('./assets/images/WATERPEACE.svg') no-repeat;
  animation: changeYemenBg 5s infinite;
}

.peruIcons {
  background: url('./assets/images/protection.svg') no-repeat;
  animation: changePeruBg 5s infinite;
}

.zambiaIcons {
  background: url('./assets/images/WATERPEACE.svg') no-repeat;
  animation: changeZambiaBg 8s infinite;
}

.nileIcons {
  background: url('./assets/images/WATERPEACE.svg') no-repeat;
  animation: changeNileBg 5s infinite;
}

.africaIcons {
  background: url('./assets/images/WATERPEACE.svg') no-repeat;
  animation: changeAfricaBg 5s infinite;
}

.tunisiaIcons {
  background: url('./assets/images/WATERPEACE.svg') no-repeat;
  animation: changeTunisiaBg 8s infinite;
}
